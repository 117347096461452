@font-face {
    font-family: 'Roc Grotesk';
    src: local('Roc Grotesk'), url('../public/Roc-Grotesk-W05-Wide-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

::-webkit-scrollbar {
    width: 0.625rem;
}

::-webkit-scrollbar-track {
    background: #FFEB0014;
}

::-webkit-scrollbar-thumb {
    background: #FF6200;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

html, body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFEB0014;
    color: #000;
    min-height: 100vh;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 500;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    font-family: 'Satoshi', sans-serif;
    transition: all 0.25s ease-in-out;
}

body {
    background: url("./assets/svg/bgPattern.svg") no-repeat;
    background-size: auto;
    background-position: -2% 41%;
}

@media (max-width: 1023px) {
    body {
        background-position: 41% 41%;
    }
}

img {
    max-width: 100%;
    transition: all 0.25s ease-in-out;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: clamp(2.3rem, 7vw, 3.9rem);
    letter-spacing: -2.5px !important;
    margin-bottom: 2em !important;
    width: 85%;
    line-height: 1.2;
}

a {
    text-decoration: none;
    color: #000;
    outline: none;
    transition: all 0.25s ease-in-out;
}

button, input {
    border: 0;
    outline: 0;
    cursor: pointer;
}

h1, h2 {
    font-family: 'Roc Grotesk', sans-serif;
    letter-spacing: -1px;
    margin-bottom: 0.5em;
}

.container {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
}

main {
    margin-top: 4em;
}

.cardsContainer {
    max-width: 1440px;
    display: flex;
    overflow-y: hidden;
    flex-wrap: nowrap;
    gap: 2.7em;
    padding: 0 1em 0 3em;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    /* overflow-x: scroll;
    scroll-snap-type: x mandatory; */
}


.cardsContainer::-webkit-scrollbar {
    display: none;
}

.controls {
    position: absolute;
    display: flex;
    gap: 1.2em;
    right: 0;
    top: -6.5em;
}

.controls button {
    background: none;
    outline: none;
    padding: 0;
    border: none;
}

.controls button:hover, .controls button:focus {
    transform: scale(1.3);
}

@media (min-width: 1680px) {
    .cardsContainer {
        max-width: 1706px;
        padding: 0;
        margin: 0 auto;
    }

    .controls {
        display: none;
    }

    h1 {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .controls {
        top: 0;
        position: relative;
        margin-bottom: 1.5em;
        justify-content: flex-end;
    }

    h1 {
        margin-bottom: 1em !important;
    }
}

.card {
    min-width: 18.812rem;
    max-width: 18.812rem;
    height: 26.25rem;
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 1rem;
    /* scroll-snap-align: start; */
    overflow: hidden;
}

.card:hover .cardImg img {
    transform: scale(1.2);
}

.cardImg {
    background-color: #000;
    height: 13.125rem;
}

.cardTexts {
    padding: 1.5em;
    min-height: 10.625rem;
    border-bottom: 2px solid #000;
    border-radius: 0 0 1rem 1rem;
}

.cardTexts p {
    font-size: 1rem;
    font-weight: 600;
}

.cardsContainer .card:nth-child(1) .cardTexts {
    background-color: #FFEB00;
}

.cardsContainer .card:nth-child(2) .cardTexts {
    background-color: #FF6200;
}

.cardsContainer .card:nth-child(3) .cardTexts {
    background-color: #00E2FF;
}

.cardsContainer .card:nth-child(4) .cardTexts {
    background-color: #9BDE1B;
}

.cardEngagements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em 2.5em;
}

.cardEngagements span {
    display: flex;
    font-size: 0.75rem;
    align-items: center;
    gap: 0.5em;
    font-weight: 600;
}

.comingSoonCard {
    background: #000;
    display: flex;
    gap: 1.3em;
    align-items: center;
    justify-content: center;
    width: 18.812rem;
    text-align: center;
    border-radius: 1rem;
    height: 23.75rem;
    flex-direction: column;
}

.comingSoonCard img {
    width: 11.25rem;
}

.comingSoonCard span {
    font-weight: 600;
    font-size: 1.45rem;
    background: #FF0080;
    background: linear-gradient(to right, #FF0080 14%, #FFB100 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Roc Grotesk';
}

footer.container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: flex-end;
    flex-wrap: wrap;
    font-size: 12px;
    gap: 2rem;
    padding: 4rem 0 2rem;
}

@media (max-width: 917px) {
    footer.container {
        justify-content: center;
        margin: 5rem auto 2rem !important;
    }

    /* .storesLinkWrapper {
        order: 0;
    } */
    footer p {
        order: 3;
        text-align: center;
    }

    footer nav {
        order: 2;
    }
}

@media (max-width: 593px) {
    footer {
        justify-content: center;
    }
}


footer ul {
    list-style: none;
    display: flex;
    gap: 1.7rem;
}

footer ul li a {
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: #7a7777;
    text-decoration-thickness: 1.5px;
    font-weight: 900;
}

footer li a:hover, footer li a:focus {
    text-decoration: none;
}

footer {
    display: flex;
    justify-content: space-between;
}

/* .storesLinkWrapper {
display: flex;
gap: 1rem;
} */

